import React, {useState, useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import './index.less'
import { login } from "../../action/authAction";
import { setTokenStorage } from "../../util/function";
import loginImage from "../../image/login.png"

const LoginForm = () => {
    const getWindowSize = () => window.innerHeight
    const [Height, setHeight] = useState(getWindowSize());



    useEffect(() => {
        // 监听
        window.addEventListener("resize", handleResize);
        // 销毁
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, [])

    const handleResize = () => {
        setHeight(getWindowSize());
    };


    const onFinish = (values) => {
        login(values).then(res => {
            message.success('登录成功')
            setTokenStorage(res.data.access_token)
            localStorage.setItem("username", values.name)
            setTimeout(() => {
                window.location.href = '/'
            }, 1000)
        }).catch(error => {
            console.log(error.response)
            // message.error(error.response.data.message)
        })
    }

    const FormItem = Form.Item
    
    return (
        <div style={{ width: "100%", minWidth: "1000px", height: Height + "px", display: "flex", }}>
        <div style={{ width: "100%", height: "100%", background: `url("${loginImage}") center center / cover no-repeat`, display: "flex", justifyContent: "center", alignItems: "center" }} >
            <div style={{ width: "400px", height: "350px", display: "flex",flexFlow:"column",justifyContent:"center", alignItems: "center", alignContent: "flex-start", backgroundColor:"rgba(0,0,0,0.3)" }}>
            <p style={{ color: "#fff", fontSize: "30px", fontWeight: "600",marginLeft:"30px",width:"80%" }}>Wharton System Management Portal</p>
                <Form onFinish={onFinish} style={{ width: "80%"}}>
                    <FormItem
                        name="name"
                    >
                        <Input style={{ height: "45px" }} placeholder={`Username`} />
                    </FormItem>
                    <FormItem
                        name="password"
                    >
                        <Input
                            style={{ height: "45px" }}
                            type='password'
                            placeholder={`Password`}
                        />
                    </FormItem>
                    <Button type='primary' htmlType="submit" style={{ width: "100%", height: "45px", borderRadius: "5px",fontSize:"20px" }}>
                        Login
                        </Button>
                </Form>
            </div>

        </div>

    </div >
    )
}

export default LoginForm
