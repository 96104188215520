import React, { Component } from 'react'
import { Row, Col } from 'antd'
import NavLeft from './page/navLeft/index'
import Header from './page/header/index'

import './style/common.less'
class Admin extends Component {
  render() {
    return (
      <Row className="container">
        <Col span={4} className="nav-left">
          <NavLeft />
        </Col>
        <Col span={20} className="main">
          <Header />
          <Row className="content">{this.props.children}</Row>
        </Col>
      </Row>
    )
  }
}

export default Admin
