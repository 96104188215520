export const API = {
    BASEURL: "https://wformapi.commonworths.com/api/system/",
    auth: {
        LOGIN: "login",
        partnerAddress: "system",
    },

    systemUser:"systemUser",
    User:"user",
    partner:"partner",
    Property:"property",
    Livestock:"livestock",
    Report:"report",
    Feed:"feed",
    feedResource:"feedResource"

}


