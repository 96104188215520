import { Axios } from "../util/request"
import { API } from "../util/api";


import { type } from "../util/varibales";

export const SetMenuName = (name) => {
    return (dispatch) => {
        dispatch({
            type: type.SWITCH_MENU,
            payload: name
        });
    }
}

// 登录
export const login = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "post",
        data
    })
}


// New管理员
export const AddAdmin = (data) => {
    return Axios.ajax({
        url: API.systemUser,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取管理员列表
export const GetAdminList = () => {
    return Axios.ajax({
        url: API.systemUser,
        method: "get"
    })
}



//获取管理员信息
export const getAdminDetail = (id) => {
    return Axios.ajax({
        url: API.systemUser + "/" + id,
    })
}

// 修改管理员
export const putAdmin = (data, id) => {
    return Axios.ajax({
        url: API.systemUser + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除管理员
export const deleteAdmin = (id) => {
    return Axios.ajax({
        url: API.systemUser + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// New用户
export const AddUser = (data) => {
    return Axios.ajax({
        url: API.User,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取用户列表
export const GetUserList = () => {
    return Axios.ajax({
        url: API.User,
        method: "get"
    })
}


// 修改用户
export const putUser = (data, id) => {
    return Axios.ajax({
        url: API.User + "/" + id,
        method: "post",
        tips: "修改成功",
        data,
    })
}

// 删除用户
export const deleteUser = (id) => {
    return Axios.ajax({
        url: API.User + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}



// New Investor
export const AddInvestor = (data) => {
    return Axios.ajax({
        url: API.partner,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Investor列表
export const GetInvestorList = () => {
    return Axios.ajax({
        url: API.partner,
        method: "get"
    })
}


// 修改Investor
export const putInvestor = (data, id) => {
    return Axios.ajax({
        url: API.partner + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除Investor
export const deleteInvestor = (id) => {
    return Axios.ajax({
        url: API.partner + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}




// NewProperty
export const AddProperty = (data) => {
    return Axios.ajax({
        url: API.Property,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Property列表
export const GetPropertyList = () => {
    return Axios.ajax({
        url: API.Property,
        method: "get"
    })
}


// 修改Property
export const putProperty = (data, id) => {
    return Axios.ajax({
        url: API.Property + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除Property
export const deleteProperty = (id) => {
    return Axios.ajax({
        url: API.Property + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// NewLivestock
export const AddLivestock = (data) => {
    return Axios.ajax({
        url: API.Livestock,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Livestock列表
export const GetLivestockList = () => {
    return Axios.ajax({
        url: API.Livestock,
        method: "get"
    })
}


// 修改Livestock
export const putLivestock = (data, id) => {
    return Axios.ajax({
        url: API.Livestock + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除Livestock
export const deleteLivestock = (id) => {
    return Axios.ajax({
        url: API.Livestock + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

// NewReport
export const AddReport = (data) => {
    return Axios.ajax({
        url: API.Report,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Report列表
export const GetReportList = () => {
    return Axios.ajax({
        url: API.Report,
        method: "get"
    })
}


// 修改Report
export const putReport = (data, id) => {
    return Axios.ajax({
        url: API.Report + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除Report
export const deleteReport = (id) => {
    return Axios.ajax({
        url: API.Report + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

// 获取Report详情
export const GetReportDetail = (id) => {
    return Axios.ajax({
        url: API.Report+"/"+id,
        method: "get"
    })
}

// 获取Feed列表
export const GetFeedList = (page) => {
    return Axios.ajax({
        url: API.Feed+"?page="+page,
        method: "get"
    })
}

// 修改Feed
export const putFeed = (data, id) => {
    return Axios.ajax({
        url: API.Feed + "/" + id,
        method: "post",
        tips: "修改成功",
        data,
    })
}

// 删除Feed
export const deleteFeed = (id) => {
    return Axios.ajax({
        url: API.Feed + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}



// 获取feedResource列表
export const GetFeedResourceList = (page) => {
    return Axios.ajax({
        url: API.feedResource+"?page="+page,
        method: "get"
    })
}