import React, { Component } from 'react'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons';
import './index.less'
import { data } from "../../util/data";
import { connect } from 'react-redux'
import { SetMenuName } from "../../action/authAction";
class NavLeft extends Component {

    state = {
        menuList: []
    }

    componentDidMount() {
        this.setState({ menuList: data })
    }

    handleMenuClick = ({ item }) => {
        this.props.dispatchMenu(item.props.title)
    }

    renderMenu = () => {
        const SubMenu = Menu.SubMenu
        const MenuItem = Menu.Item
        return this.state.menuList.map((item, index) => {
            if (!item.children) {
                return (
                    <MenuItem title={item.name} key={item.path}>
                        <Link to={item.path} replace>
                            {item.name}
                        </Link>
                    </MenuItem>
                )
            } else {
                return (
                    <SubMenu key={index} title={<span>{item.name}</span>}>
                        {item.children.map((children) => {
                            if (this.props.history.location.pathname === children.path) {
                                this.props.dispatchMenu(children.name)
                            }
                            return (
                                <MenuItem title={children.name} key={children.path}>
                                    <Link to={children.path} replace>
                                        {children.name}
                                    </Link>
                                </MenuItem>
                            )
                        })}
                    </SubMenu>
                )
            }
        })
    }

    render() {
        const { history } = this.props
        return (
            <div>
                <div className='logo'>
                    <h1>Wharton System Management Portal</h1>
                    <Link to="feeds">
                        <HomeOutlined style={{ fontSize: "24px", color: "#1890ff", marginLeft: "15px" }} />
                    </Link>
                </div>
                <Menu
                    selectedKeys={[history.location.pathname]}
                    defaultSelectedKeys={['1']}
                    mode='inline'
                    theme='dark'
                    onClick={this.handleMenuClick}
                >
                    {this.renderMenu()}
                </Menu>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        dispatchMenu(title) {
            dispatch(SetMenuName(title))
        }
    }
}

export default connect(null, mapDispatchToProps)(withRouter(NavLeft))
