import React, { useState, useEffect } from "react";
import { Modal, Descriptions,Pagination } from 'antd'
import fileImg from "../image/file.png"
import videoImg from "../image/video.png"
import { GetFeedResourceList } from "../action/authAction";

const Multiple = () => {

    const [data, setData] = useState([])
    const [showItem, setShowItem] = useState("")
    const [ItemUrl, setItemUrl] = useState("")
    const [isShow, setIsShow] = useState(false)
    const [projectData, setProjectData] = useState("")


    useEffect(() => {
        getData(1)
    }, [])

    const getData = (page) => {
        GetFeedResourceList(page).then(res => {
            setData(res.data.data)
            if (res.data.meta && res.data.meta.pagination) {
                setProjectData(res.data.meta.pagination)
            }
        })
    }


    const handleShowItem = (data) => {
        let url = ""
        let num = data.url.indexOf("?v=");
        if (num > 0) {
            url = data.url.substring(num + 3, data.url.length);
        }

        setItemUrl(url)
        setShowItem(data)
        setIsShow(true)
    }


    return (
        <div style={{width:"1405px"}}>
            <div style={{ width: "1405px", height: "840px", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }}>
                {
                    data.map((item, index) => {
                        let srcUrl = ""
                        if (item.type === "image") {
                            srcUrl = item.url
                        }
                        if (item.type === "video") {
                            srcUrl = videoImg
                        }
                        if (item.type === "file") {
                            srcUrl = fileImg
                        }
                        return <img
                            key={index}
                            src={srcUrl}
                            style={{ margin: "10px", width: "120px", height: "120px" }}
                            onClick={() => handleShowItem(item)}
                            alt=""
                        />


                    })
                }

            </div>
            {
                isShow && showItem &&
                <Modal
                    title="Detail"
                    visible={isShow}
                    onCancel={() => {
                        setIsShow(false)
                        setShowItem("")
                    }}
                    footer={null}
                >
                    {
                        showItem.type === "image" && <img src={showItem.url} style={{ width: "100%" }} alt="" />
                    }
                    {
                        showItem.type === "video" && <iframe width="490" height="300" src={"https://www.youtube.com/embed/" + ItemUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    }
                    {
                        showItem.type === "file" && <img src={fileImg} style={{ width: "100%" }} alt="" />
                    }

                    <div style={{ width: "100%", height: "auto", marginTop: "30px" }}>
                        <Descriptions
                            title="Detail"
                            size="middle"
                            column={1}
                        >
                            <Descriptions.Item label="Create by">{showItem.feed && showItem.feed.create_by}</Descriptions.Item>
                            <Descriptions.Item label="Feed Id">{showItem.feed_id}</Descriptions.Item>
                            <Descriptions.Item label="Created at">{showItem.created_at && showItem.created_at.date.substring(0, showItem.created_at.date.length - 7)}</Descriptions.Item>
                            <Descriptions.Item label="Updated at">{showItem.updated_at && showItem.updated_at.date.substring(0, showItem.created_at.date.length - 7)}</Descriptions.Item>
                            <Descriptions.Item label="Url">{showItem.url}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </Modal>
            }
            {
                projectData && projectData.total > projectData.per_page &&
                <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px", paddingBottom: "30px" }}>
                    <Pagination
                        // defaultCurrent={page}
                        // current={page}
                        pageSize={projectData && projectData.per_page}
                        total={projectData.total}
                        showSizeChanger={false}
                        onChange={(e) => {
                            getData(e)
                        }} />
                </div>
            }
        </div>

    );
}

export default Multiple

