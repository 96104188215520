import React, { useState, useEffect } from "react";
// import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, Button, Modal, Form, Select, Descriptions, Row, Col } from 'antd';
import { AddLivestock, putReport, putLivestock, deleteLivestock, GetPropertyList, GetReportDetail } from "../action/authAction";

const LivesStock = () => {
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 20, span: 16 },
    };
    const { TextArea } = Input;
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '5%',
            align: "center",
        },
        {
            title: 'Property',
            width: '10%',
            align: "center",
            render: (record) => {
                return record.reportField.property && record.reportField.property.name
            }
        },
        {
            title: 'Stock Classification',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField.stock_classification
            }
        },
        {
            title: 'Muster Number',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField.muster_number
            }
        },
        {
            title: 'Muster Percentage',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField.muster_percentage
            }
        },
        {
            title: 'Source',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField.source
            }
        },
        {
            title: 'Mortality Estimate',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField.mortality_estimate
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            align: "center",
            render: (text, record) => {
                return <div>
                    <Button
                        type="link"
                        onClick={() => {
                            setNewVisible(false)
                            setEditVisible(true)
                            setLiveStockField(record.reportField)
                            setId(record.id)
                            setPropertyId(record.reportField.property_id)
                            setStock(record.reportField.stock_classification)
                            setSource(record.reportField.source)
                        }}
                    >Edit</Button>
                    <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record.id)}>Delete</Button>
                </div>
            },
        },

    ];

    const [data, setData] = useState([])
    const [NewVisible, setNewVisible] = useState(false)
    const [EditVisible, setEditVisible] = useState(false)
    const [LiveStockField, setLiveStockField] = useState([])
    const [ReportField, setReportField] = useState("")
    const [Id, setId] = useState("")
    const [ReportId, setReportId] = useState("")
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [PropertyList, setPropertyList] = useState([])
    const [StatusList] = useState(["archive", "superceded", "resolved", "approved", "rejected", "closed"])
    const [StockList] = useState(["Bush Cows", "Herd bulls", "Calves", "Weaners Heifers", "Mixed cattle"])
    const [SourceList] = useState(["Sales", "Purchases", "Trucked from another property", "Agisted Cattle for Fattening"])
    const [property_id, setPropertyId] = useState('')
    const [Stock, setStock] = useState('')
    const [Source, setSource] = useState('')
    const [Disabled, setDisabled] = useState(true)
    const [Status, setStatus] = useState('')
    const [PropertyIds, setPropertyIds] = useState([])
    const [PropertyNames, setPropertyPropertyNames] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        let str = window.location.hash
        let index = str.lastIndexOf("?id=")
        str = str.substring(index + 4, str.length);
        setReportId(str)
        GetReportDetail(str).then(res => {
            let property = res.data.property.data
            let propertyId = []
            let propertyName = []
            for (let i in property) {
                propertyId.push(property[i].id)
                propertyName.push(property[i].name)
            }
            setPropertyIds(propertyId)
            setPropertyPropertyNames(propertyName)
            setReportField(res.data)
            setData(res.data.livestock.data)
            setStatus(res.data.status)
        })

        GetPropertyList().then(res => {
            setPropertyList(res.data.data)
        })

    }

    const handleDelete = (id) => {
        deleteLivestock(id).then(res => {
            getData()
        })
    }

    const HandleSaveData = (values) => {

        if (Disabled) {
            values.status = Status
            let data = []
            for (let i in PropertyIds) {
                data.push({ "property_id": PropertyIds[i] })
            }
            values.report_property = data
            putReport(values, ReportId).then(res => {
                getData()
                setEditVisible(false)
            }).catch(error => {
                console.log(error.response)
            })
        }

    };

    const onFinish = (values) => {
        let report_field = {}
        report_field["report_field"] = {}
        values.property_id = property_id
        values.stock_classification = Stock
        values.source = Source
        report_field.report_id = ReportId
        for (let i in values) {
            report_field["report_field"][i] = values[i];
        }
        if (EditVisible) {
            putLivestock(report_field, Id).then(res => {
                getData()
                setEditVisible(false)
            }).catch(error => {
                console.log(error.response)
            })
        } else {
            AddLivestock(report_field).then(res => {
                getData()
                setNewVisible(false)
            }).catch(error => {
                console.log(error.response)
            })
        }
    };

    return (
        <div style={{ width: "100%" }}>
            {
                ReportField &&
                <div style={{ backgroundColor: "#fff", marginBottom: "20px", paddingRight: "20px" }}>
                    <Form
                        {...layout}
                        name="basic1"
                        onFinish={HandleSaveData}
                    >
                        <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={() => {
                                    if (Disabled) {
                                        setDisabled(false)
                                    } else {
                                        setDisabled(true)
                                    }
                                }}
                            >
                                {
                                    Disabled ? "Edit" : "Save"
                                }
                            </Button>
                        </div>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="uuid"
                                    name="uuid"
                                    initialValue={ReportField.uuid}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    initialValue={ReportField.name}
                                >
                                    <Input disabled={Disabled} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="status">
                                    <Select
                                        disabled={Disabled}
                                        onChange={(value) => setStatus(value)}
                                        defaultValue={ReportField.status}
                                    >
                                        {
                                            StatusList.map((item, index) => {
                                                return <Select.Option key={index} value={item}>{item}</Select.Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Reporter"
                                    name="reporter"
                                    initialValue={ReportField.reporter}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    label="Feedback"
                                    name="feedback"
                                    initialValue={ReportField.feedback}
                                // labelCol={{ span: 2,offset:1}}
                                // wrapperCol={{ span: 21,offset:0}}
                                >
                                    <TextArea disabled={Disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Property">
                                    <Select
                                        mode="multiple"
                                        defaultValue={PropertyNames}
                                        disabled={Disabled}
                                        onChange={(value) => setPropertyIds(value)}
                                    >
                                        {
                                            PropertyList.map((item, index) => {
                                                return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    label="Created by"
                                >
                                    <Input disabled={true} value={ReportField.create_by} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Updated by"
                                >
                                    <Input disabled={true} value={ReportField.update_by} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Created at"
                                >
                                    <Input disabled={true} value={ReportField.created_at && ReportField.created_at.date.substring(0, ReportField.created_at.date.length - 7)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Updated at"
                                >
                                    <Input disabled={true} value={ReportField.updated_at && ReportField.updated_at.date.substring(0, ReportField.updated_at.date.length - 7)} />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </div>
            }
            <div>
                {/* <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h3>Section 1： Livestock </h3>
                    <Button
                        type="primary"
                        onClick={() => {
                            setNewVisible(true)
                        }} style={{ marginRight: "10px" }}>
                        <PlusOutlined /> New
                    </Button>
                </div> */}
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender: record => {
                            let reportField = record.reportField
                            return <Descriptions
                                title="Detail"
                                size="default"
                                style={{ marginLeft: "70px" }}
                            >
                                <Descriptions.Item label="Property">{reportField.property && reportField.property.name}</Descriptions.Item>
                                <Descriptions.Item label="Stock Classification">{reportField.stock_classification}</Descriptions.Item>
                                <Descriptions.Item label="Muster Number">{reportField.muster_number}</Descriptions.Item>
                                <Descriptions.Item label="Muster Percentage">{reportField.muster_percentage}</Descriptions.Item>
                                <Descriptions.Item label="Source">{reportField.source}</Descriptions.Item>
                                <Descriptions.Item label="Mortality Estimate">{reportField.mortality_estimate}</Descriptions.Item>
                                <Descriptions.Item label="Created by">{record.create_by}</Descriptions.Item>
                                <Descriptions.Item label="Updated by">{record.update_by}</Descriptions.Item>
                                <Descriptions.Item label="Created at">{reportField.created_at && reportField.created_at.date.substring(0, reportField.created_at.date.length - 7)}</Descriptions.Item>
                                <Descriptions.Item label="Updated at">{reportField.updated_at && reportField.updated_at.date.substring(0, reportField.created_at.date.length - 7)}</Descriptions.Item>
                            </Descriptions>
                        },
                        onExpand: (e, record) => {
                            let isClose = JSON.stringify(expandedRowKeys) === JSON.stringify([record.id])
                            setExpandedRowKeys(!isClose ? [record.id] : [])
                        },
                        expandedRowKeys: expandedRowKeys
                    }}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    pagination={false}
                />
                {
                    NewVisible &&
                    <Modal
                        title="New"
                        visible={NewVisible}
                        onCancel={() => setNewVisible(false)}
                        footer={null}
                    >
                        <Form
                            {...layout}
                            name="basic"
                            onFinish={onFinish}
                        >
                            <Form.Item label="Property">
                                <Select
                                    onChange={(value) => setPropertyId(value)}
                                >
                                    {
                                        PropertyList.map((item, index) => {
                                            return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item label="stock">
                                <Select
                                    onChange={(value) => setStock(value)}
                                >
                                    {
                                        StockList.map((item, index) => {
                                            return <Select.Option key={index} value={item}>{item}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item label="Source">
                                <Select
                                    onChange={(value) => setSource(value)}
                                >
                                    {
                                        SourceList.map((item, index) => {
                                            return <Select.Option key={index} value={item}>{item}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Percentage"
                                name="muster_percentage"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Number"
                                name="muster_number"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Mortality"
                                name="mortality_estimate"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                }
                {
                    EditVisible &&
                    <Modal
                        title="Edit"
                        visible={EditVisible}
                        onCancel={() => {
                            setPropertyId('')
                            setStock('')
                            setSource('')
                            setEditVisible(false)
                        }}
                        footer={null}
                    >
                        <Form
                            {...layout}
                            name="basic"
                            onFinish={onFinish}
                        >
                            <Form.Item label="Property">
                                <Select
                                    defaultValue={LiveStockField.property && LiveStockField.property.name}
                                    onChange={(value) => setPropertyId(value)}
                                >
                                    {
                                        PropertyList.map((item, index) => {
                                            return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item label="stock">
                                <Select
                                    defaultValue={LiveStockField.stock_classification}
                                    onChange={(value) => setStock(value)}
                                >
                                    {
                                        StockList.map((item, index) => {
                                            return <Select.Option key={index} value={item}>{item}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item label="Source">
                                <Select
                                    defaultValue={LiveStockField.source}
                                    onChange={(value) => setSource(value)}
                                >
                                    {
                                        SourceList.map((item, index) => {
                                            return <Select.Option key={index} value={item}>{item}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Percentage"
                                name="muster_percentage"
                                initialValue={LiveStockField.muster_percentage}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Number"
                                name="muster_number"
                                initialValue={LiveStockField.muster_number}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Mortality"
                                name="mortality_estimate"
                                initialValue={LiveStockField.mortality_estimate}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </div>
        </div>
    );
}

export default LivesStock

