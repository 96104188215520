import {type} from "../util/varibales";

const INITIAL_STATE = {
    // menuName: "首页"
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case type.SWITCH_MENU:
            return {...state,menuName:action.payload}
        default:
            return state;
    }
}
