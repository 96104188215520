export const data = [
    {
        "name": "Admin",
        "path": "/admin",
    },
    {
        "name": "User",
        "path": "/user",
    },
    {
        "name": "investor",
        "path": "/investor",
    },
    {
        "name": "Property",
        "path": "/property",
    },
    {
        "name": "live stock",
        "path": "/livestock",
    },
    {
        "name": "Report",
        "path": "/report",
    },
    {
        "name": "Feeds",
        "path": "/feedlist",
        "icon": "SettingOutlined"
    },
    {
        "name": "Multiple Mideas",
        "path": "/multiple",
    },

]
