import React, { useState, useEffect } from "react";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Table, Input, Button, Modal, Form, Upload, message } from 'antd';
import { AddUser, GetUserList, putUser, deleteUser } from "../action/authAction";

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

const UserList = () => {

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 20, span: 16 },
    };

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '30%',
            editable: false,
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '30%',
            editable: true,
            align: "center",
        },
        {
            title: 'Name',
            width: '30%',
            align: "center",
            render: (record) => {
                return <img src={record.header_image} alt="" style={{width:"50px"}}/>
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: '30%',
            align: "center",
            render: (text, record) => {
                return <div>
                    <Button type="link" style={{ marginRight: "10px" }}
                        onClick={() => {
                            setNewVisible(false)
                            setEditVisible(true)
                            setItem(record)
                            setImageUrl(record.header_image)
                        }}
                    >Edit</Button>
                    <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record)}>Delete</Button>
                </div>
            },
        },

    ];

    const [data, setData] = useState([])
    const [NewVisible, setNewVisible] = useState(false)
    const [EditVisible, setEditVisible] = useState(false)
    const [item, setItem] = useState([])
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState("")
    const [imageUrlFile, setImageUrlFile] = useState("")


    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetUserList().then(res => {
            setData(res.data.data)
        })
    }

    const handleDelete = (item) => {
        deleteUser(item.id).then(res => {
            getData()
        })
    }

    const onFinish = (values) => {
        let formData = new FormData();
        for (let i in values) {
            formData.append(i, !!values[i] ? values[i] : '');
        }
        if (imageUrlFile) {
            formData.append("header_file", imageUrlFile);
        }

        if (EditVisible) {
            if (!values.password) {
                formData.delete("password")
            }
            putUser(formData, item.id).then(res => {
                getData()
                setEditVisible(false)
            })
        } else {
            AddUser(formData).then(res => {
                getData()
                setNewVisible(false)
            })
        }
    };


    const handleChange = info => {
        setImageUrlFile(info.file)
        let isOk = beforeUpload(info.file)
        if (isOk) {
            setLoading(true)
            getBase64(info.file, imageUrl => {
                setImageUrl(imageUrl)
                setLoading(false)
            }
            );
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button type="primary" onClick={() => {
                    setNewVisible(true)
                }} style={{ marginRight: "10px" }}>
                    <PlusOutlined /> New
                </Button>
            </div>
            <Table
                rowKey='id'
                dataSource={data}
                columns={columns}
                pagination={{
                    hideOnSinglePage: true
                }}
            />
            {
                NewVisible &&
                <Modal
                    title="New"
                    visible={NewVisible}
                    onCancel={() => setNewVisible(false)}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="name"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Avatar"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                beforeUpload={(file) => {
                                    return false;
                                }}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div>
                                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>}
                            </Upload>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
            {
                EditVisible &&
                <Modal
                    title="Edit"
                    visible={EditVisible}
                    onCancel={() => setEditVisible(false)}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="name"
                            initialValue={item.name}
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Avatar"
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                onChange={handleChange}
                                beforeUpload={(file) => {
                                    return false;
                                }}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <div>
                                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div style={{ marginTop: 8 }}>Upload</div>
                                </div>}
                            </Upload>
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }

        </div>
    );
}

export default UserList

