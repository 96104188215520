import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, Button, Modal, Form } from 'antd';
import { AddAdmin, GetAdminList, putAdmin, deleteAdmin } from "../action/authAction";



const AdminList = () => {
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 20, span: 16 },
    };

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '30%',
            editable: false,
            align: "center",
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '30%',
            editable: true,
            align: "center",
        },
        {
            title: 'Action',
            key: 'action',
            width: '30%',
            align: "center",
            render: (text, record) => {
                if (record.name !== "admin") {
                    return <div>
                        <Button type="link" style={{ marginRight: "10px" }}
                            onClick={() => {
                                setNewVisible(false)
                                setEditVisible(true)
                                setItem(record)
                            }}
                        >Edit</Button>
                        <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record)}>Delete</Button>
                    </div>
                }
            },
        },

    ];

    const [data, setData] = useState([])
    const [NewVisible, setNewVisible] = useState(false)
    const [EditVisible, setEditVisible] = useState(false)
    const [item, setItem] = useState([])


    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetAdminList().then(res => {
            setData(res.data.data)
        })
    }

    const handleDelete = (item) => {
        deleteAdmin(item.id).then(res => {
            getData()
        })
    }

    const onFinish = (values) => {
        if (EditVisible) {
            if(!values.password){
                delete values.password
            }
            putAdmin(values, item.id).then(res => {
                getData()
                setEditVisible(false)
            })
        } else {
            AddAdmin(values).then(res => {
                getData()
                setNewVisible(false)
            })
        }
    };



    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button type="primary" onClick={() => {
                    setNewVisible(true)
                }} style={{ marginRight: "10px" }}>
                    <PlusOutlined /> New
                </Button>
            </div>
            <Table
                rowKey='id'
                dataSource={data}
                columns={columns}
                pagination={{
                    hideOnSinglePage: true
                }}
            />
            {
                NewVisible &&
                <Modal
                    title="New"
                    visible={NewVisible}
                    onCancel={()=>setNewVisible(false)}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="name"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
            {
                EditVisible &&
                <Modal
                    title="Edit"
                    visible={EditVisible}
                    onCancel={()=>setEditVisible(false)}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="name"
                            initialValue={item.name}
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                        >
                            <Input.Password />
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }

        </div>
    );
}

export default AdminList

