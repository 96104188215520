import React, { useState, useEffect } from "react";
// import { PlusOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import { Link } from "react-router-dom"
import { 
    // AddReport,
     GetReportList, deleteReport } from "../action/authAction";

const LivesStock = () => {
    // const layout = {
    //     labelCol: { span: 8 },
    //     wrapperCol: { span: 16 },
    // };
    // const tailLayout = {
    //     wrapperCol: { offset: 20, span: 16 },
    // };
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '5%',
            align: "center",
        },
        {
            title: 'Report Name',
            dataIndex: 'name',
            width: '10%',
            align: "center",

        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '10%',
            align: "center",

        },
        {
            title: 'Reporter',
            dataIndex: 'reporter',
            width: '10%',
            align: "center",

        },
        {
            title: 'Feedback',
            dataIndex: 'feedback',
            width: '10%',
            align: "center",

        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            align: "center",
            render: (text, record) => {
                let path = `/reportdetail?id=${record.id}`
                return <div>
                    <Link to={path}>Edit</Link>
                    <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record.id)}>Delete</Button>
                </div>
            },
        },

    ];

    const [data, setData] = useState([])
    // const [NewVisible, setNewVisible] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetReportList().then(res => {
            setData(res.data.data)
        }).catch(error => {
            
        })


    }

    const handleDelete = (id) => {
        deleteReport(id).then(res => {
            getData()
        })
    }

    // const onFinish = () => {
    //     AddReport().then(res => {
    //         let path = `/reportdetail?id=${res.data.id}`
    //         window.location.href = path
    //     }).catch(error => {

    //     })
    // };

    return (
        <div style={{ width: "100%" }}>
            {/* <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button type="primary" onClick={() => {
                    // setNewVisible(true)
                    onFinish()

                }} style={{ marginRight: "10px" }}>
                    <PlusOutlined /> New
                </Button>
            </div> */}
            <Table
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
            />
            {/* {
                NewVisible &&
                <Modal
                    title="New"
                    visible={NewVisible}
                    onCancel={() => setNewVisible(false)}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >

                        <Form.Item
                            label="Name"
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Reporter"
                            name="reporter"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="feedback"
                            name="feedback"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            } */}
        </div>
    );
}

export default LivesStock

